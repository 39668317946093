/* eslint
    prettier/prettier: "off",
    func-names: "off",
    no-unused-vars: "off",
    eqeqeq: "off",
    no-useless-concat: "off",
    no-unused-expressions: "off",
    object-shorthand: "off"
*/
(function($) {
    const { MONTHLY_FREQUENCY} = require('./utils/donation-form-helper.js')

    Drupal.behaviors.LegacyOtherFieldFormJS = {
      attach: function (context, settings) {
        $(document).ready(function() {

            var donationType = $("input[type='hidden'][name='submitted[donation][recurs_monthly]']").val();
            var donationRadioAmountsBlock = $('#edit-submitted-donation-amount');
            var donationRecurringRadioAmountsBlock = $('#edit-submitted-donation-recurring-amount');
            var otherID = $('#webform-component-donation--amount').find('input[type="radio"][value="other"]').attr('id');
            var recurOtherID = $('#webform-component-donation--recurring-amount').find('input[type="radio"][value="other"]').attr('id');
        
            // Hack to fix bug when form is recurring only but other field and donation select amount wrapper are not tagged with
            // the recurring keyword.
            if (donationType == MONTHLY_FREQUENCY) {
                // Make sure other textfield is set after other amount radio button
                if (!$('.other-amount-wrapper', context).isAfter($('#'+otherID).parent().closest('div'))) {
                    $('.other-amount-wrapper', context).insertAfter(donationRadioAmountsBlock.find('div.form-type-radio').last());
                }
            } else {
                // If it's a switcher or one time form we can use the recurring keyword to apply code
                // Make sure other textfield is set after other amount radio button for switcher and one type forms
                if (!$('.other-amount-wrapper', context).isAfter($('#'+otherID).parent().closest('div'))) {
                    $('.other-amount-wrapper', context).insertAfter(donationRadioAmountsBlock.find('div.form-type-radio').last());
                }
                if (!$('.recurring-other-amount-wrapper', context).isAfter($('#'+recurOtherID).parent().closest('div'))) {
                    $('.recurring-other-amount-wrapper', context).insertAfter(donationRecurringRadioAmountsBlock.find('div.form-type-radio').last());
                }
            }
           
         });
      }
    }
})(jQuery);
  